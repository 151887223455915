.Container {
  @apply px-4 relative md:px-0 bg-white rounded-3xl;
}

.Content {
  @apply grid col-span-6 items-stretch md:grid-cols-12;
}

.FullHeightContent {
  @apply h-screen;
}

.SummaryContent {
  @apply md:min-h-[720px];
  @apply sm:w-95 md:mx-auto lg:w-[1050px];
}
