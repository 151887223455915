@tailwind base;
@tailwind components;
@tailwind utilities;

html {
  scroll-behavior: smooth;
  -webkit-overflow-scrolling: touch;
}
body {
  -webkit-overflow-scrolling: touch;
}
[type='radio']:checked {
  background-image: url('../src/svg/radio-background.svg');
}
@font-face {
  font-family: 'ESKlarheitGrotesk';
  font-style: normal;
  font-weight: 400;
  src: url('./fonts/ESKlarheitGrotesk-Regular.woff2') format('woff2'),
    url('./fonts/ESKlarheitGrotesk-Regular.woff') format('woff');
}

@font-face {
  font-family: 'ESKlarheitGrotesk';
  font-style: normal;
  font-weight: 500;
  src: url('./fonts/ESKlarheitGrotesk-Medium.woff2') format('woff2'),
    url('./fonts/ESKlarheitGrotesk-Medium.woff') format('woff');
}

@font-face {
  font-family: 'ESKlarheitGrotesk';
  font-style: normal;
  font-weight: 700;

  src: url('./fonts/ESKlarheitGrotesk-Bold.woff2') format('woff2'),
    url('./fonts/ESKlarheitGrotesk-Bold.woff') format('woff');
}

@font-face {
  font-family: 'ESKlarheitGrotesk';
  font-style: normal;
  font-weight: 800;
  src: url('./fonts/ESKlarheitGrotesk-Extrabold.woff2') format('woff2'),
    url('./fonts/ESKlarheitGrotesk-Extrabold.woff') format('woff');
}
