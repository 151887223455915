.Spinner {
  @apply relative col-span-full h-full;
}

.SpinnerContainer {
  @apply absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2;
}

.Icon {
  @apply w-10 h-10 text-brand-green-dark animate-spin;
}
